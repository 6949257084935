/* eslint-disable no-useless-escape */
console.log(`

██████╗░███████╗░█████╗░░█████╗░██╗░░░░░██╗
██╔══██╗██╔════╝██╔══██╗██╔══██╗██║░░░░░██║
██║░░██║█████╗░░██║░░╚═╝███████║██║░░░░░██║
██║░░██║██╔══╝░░██║░░██╗██╔══██║██║░░░░░██║
██████╔╝███████╗╚█████╔╝██║░░██║███████╗██║
╚═════╝░╚══════╝░╚════╝░╚═╝░░╚═╝╚══════╝╚═╝

      ---- JS Utils Generator ----

 Created especially for you by Titus Decali

        https://titusdecali.com/

`)
